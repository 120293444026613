import React from "react"
import Layout from "../components/common/layout"
import aforismiStyles from "../styles/pages/aforismi.module.scss"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const AforismiPage = ({ data, location }) => {
  return (
    <Layout
      pageMeta={{
        title: "Aforismi",
        keywords: ["massoterapia", "savona", "parole belle", "riflessologia plantare", "vita"],
        description:
          "I miei Aforismi, una frase per illuminare di positività ogni giornata.",
        link: "/aforismi",
      }}
      location={location}
    >
      <h1 className="page-title">aforismi</h1>
      <ul className={aforismiStyles.aforismi}>
        {data.allStrapiAforismi.edges.map(post => (
              <Img
                key={post.node.strapiId}
                className={aforismiStyles.foto}
                fluid={post.node.foto.childImageSharp.fluid}
                alt={post.node.alt_text}
              />   
        ))}
      </ul>
    </Layout>
  )
}

export default AforismiPage

export const aforismiQuery = graphql`
  query AforismiPageQuery {
    allStrapiAforismi(sort: {fields: createdAt, order: ASC}) {
      edges {
        node {
          strapiId
          alt_text
          foto {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`